     
@font-face {
     font-family: 'Raleway';
     src: url('./Raleway-VariableFont_wght.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
   }
   
   @font-face {
     font-family: 'Oswald';
     src: url('./Oswald-VariableFont_wght.ttf') format('truetype');
     font-weight: 400;
     font-style: normal;
   }
   
   