/* Styl podstawowy dla animowanych nóg */
#legs1 {
    transform-origin: center center; /* Punkt obrotu ustawiony na górny środek */
    animation: sway 1s linear infinite; /* Animacja z efektem kiwania */
}

#legs2 {
    transform-origin: center center; /* Punkt obrotu ustawiony na górny środek */
    animation: sway 1s linear infinite reverse; /* Animacja z efektem kiwania */
}

#legs3 {
    transform-origin: center center; /* Punkt obrotu ustawiony na górny środek */
    animation: sway 1s linear infinite ; /* Animacja z efektem kiwania */
}

/* Definicja kluczowych klatek animacji */
@keyframes sway {
    0% {
        transform: rotate(0deg); /* Początkowa pozycja */
    }
    25% {
        transform: rotate(-5deg); /* Kąt odchylenia w jedną stronę */
    }
    50% {
        transform: rotate(0deg); /* Powrót do pozycji środkowej */
    }
    75% {
        transform: rotate(5deg); /* Kąt odchylenia w drugą stronę */
    }
    100% {
        transform: rotate(0deg); /* Powrót do pozycji wyjściowej */
    }
}
