.worldMap path {
    fill: var(--color-light); 
    stroke: #fff;
    stroke-width: 1;
    position: relative;
  }
  
  .highlighted {
    fill: var(--color-accent)!important ; 
    stroke:#fff;
    
  }

  .highlighted:hover, .highlighted-hovered {

    fill: var(--color-dark)!important;
  }